<div class="overlay-page overlay-theme">
  <div class="header">
    <div class="flex-left-placeholder"></div>
    <h1 mat-dialog-title class="title" *ngIf="!isEdit">
      {{ "APP.TARGET_GROUPS.SAVE.CREATE_TITLE" | translate }}
    </h1>
    <h1 mat-dialog-title class="title" *ngIf="isEdit">
      {{ "APP.TARGET_GROUPS.SAVE.EDIT_TITLE" | translate }}
    </h1>
    <i class="fal fa-times-circle" (click)="cancel()"></i>
  </div>

  <div class="body-info">
    <form [formGroup]="form">
      <div>
        <h2 class="body-header">
          {{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.BASIC_INFORMATION.TITLE"
              | translate
          }}*
        </h2>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="placeholder">{{
            "APP.TARGET_GROUPS.SAVE.NAME" | translate
          }}</mat-label>
          <input type="text" matInput formControlName="name" placeholder="" />
          @if (form.controls["name"].hasError("required")) {
            <mat-error>
              {{ "APP.TARGET_GROUPS.ERRORS.NAME_REQUIRED" | translate }}
            </mat-error>
          }
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="placeholder">{{
            "APP.TARGET_GROUPS.SAVE.DESCRIPTION" | translate
          }}</mat-label>
          <input
            type="text"
            matInput
            formControlName="description"
            placeholder=""
          />
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="placeholder">{{
            "APP.TARGET_GROUPS.SAVE.TONE_OF_VOICE" | translate
          }}</mat-label>
          <input
            type="text"
            matInput
            formControlName="toneOfVoice"
            placeholder=""
          />
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="placeholder">{{
            "APP.TARGET_GROUPS.SAVE.EVENT_MATERIALS_IMAGE_STYLE" | translate
          }}</mat-label>
          <input
            type="text"
            matInput
            formControlName="eventMaterialsImageStyle"
            placeholder=""
          />
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="placeholder">{{
            "APP.TARGET_GROUPS.SAVE.VISUAL_ELEMENT_STYLE" | translate
          }}</mat-label>
          <input
            type="text"
            matInput
            formControlName="visualElementStyle"
            placeholder=""
          />
        </mat-form-field>
      </div>

      <h2 class="body-header">
        {{
          "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.CHOOSE_CATEGORY"
            | translate
        }}*
      </h2>
      <app-category-select
        [form]="form"
        selectedFormControlName="categories"
        [eventCategories]="categories"
        requiredError="APP.AUTH.ERRORS.PRODUCT_CATEGORY_REQUIRED"
      ></app-category-select>

      <h2 class="body-header">
        {{
          "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.CHOOSE_SUBCATEGORY"
            | translate
        }}*
      </h2>
      <app-category-select
        [form]="form"
        selectedFormControlName="subcategories"
        [eventCategories]="subcategories"
        requiredError="APP.AUTH.ERRORS.PRODUCT_SUBCATEGORY_REQUIRED"
      ></app-category-select>
    </form>
  </div>

  <div class="floating-button-container">
    <button
      *ngIf="!isEdit"
      (click)="createOrUpdate()"
      type="button"
      mat-raised-button
      class="actionButton btn btn-primary addButton"
    >
      {{ "APP.TARGET_GROUPS.SAVE.CREATE_ACTION" | translate }}
    </button>

    <button
      *ngIf="isEdit"
      (click)="createOrUpdate()"
      type="button"
      mat-raised-button
      class="actionButton btn btn-primary editButton"
    >
      {{ "APP.TARGET_GROUPS.SAVE.UPDATE_ACTION" | translate }}
    </button>
  </div>
</div>
