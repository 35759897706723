<mat-form-field
  appearance="outline"
  [ngClass]="formFieldClass"
  [formGroup]="form"
>
  <input
    #input
    type="text"
    placeholder="{{ 'APP.TYPE_IN_OR_SELECT' | translate }}"
    matInput
    [matAutocomplete]="auto"
    [formControlName]="selectedFormControlName"
    (input)="filter()"
    (focus)="filter(true)"
    (blur)="checkValue()"
  />
  <i matSuffix class="fas fa-caret-down autocomplete-dropdown-icon"></i>

  <mat-autocomplete
    #auto="matAutocomplete"
    requireSelection
    [displayWith]="displayFn.bind(this)"
  >
    @for (
      item of filteredItems;
      track valueProperty ? item[valueProperty] : item
    ) {
      <mat-option
        [value]="useValueProperty && valueProperty ? item[valueProperty] : item"
      >
        {{ getMatOptionDisplay(item) }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
