<div class="select-or-add-cont flex-inputs-cont">
  <mat-form-field
    appearance="outline"
    class="flex-half flex-input"
    [ngClass]="{
      'ng-invalid mdc-text-field--invalid':
        (inputControl.touched || selectedFormControl.touched) &&
        selectedFormControl.invalid
    }"
  >
    <input
      type="text"
      placeholder="{{ 'APP.TYPE_IN_OR_SELECT' | translate }}"
      matInput
      [matAutocomplete]="auto"
      [formControl]="inputControl"
    />
    <i matSuffix class="fas fa-caret-down autocomplete-dropdown-icon"></i>
  </mat-form-field>
  <div>{{ "APP.OR" | translate }}</div>
  <div class="btn btn-primary" (click)="addAndSelect()">
    {{ "APP.ADD_NEW" | translate }}
  </div>
</div>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option
    *ngFor="let item of filteredItems | async"
    [value]="item.id"
    (click)="optionClicked($event, item.id)"
  >
    <div>
      <mat-checkbox
        [checked]="isSelected(item.id)"
        (change)="toggleSelection(item.id)"
        (click)="$event.stopPropagation()"
      >
        {{ item.name }}
      </mat-checkbox>
    </div>
  </mat-option>
</mat-autocomplete>

<mat-chip-set class="autocomplete-select-chip-set">
  <mat-chip
    *ngFor="let item of selectedItems"
    selectable
    removable
    (removed)="remove(item)"
    class="autocomplete-list-chip"
  >
    {{ getDisplayName(item) }}
    <span matChipRemove><i class="far fa-times"></i></span>
  </mat-chip>
</mat-chip-set>
