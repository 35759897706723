import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TargetGroup } from '@models/shared/target-group.model';
import { MatDialog } from '@angular/material/dialog';
import { TargetGroupOverlayPageComponent } from '@modules/admin/target-groups/dialogs/target-group-overlay-page/target-group-overlay-page.component';

@Component({
  selector: 'app-target-groups-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    MatChipsModule,
    MatCheckboxModule,
  ],
  templateUrl: './target-groups-select.component.html',
  styleUrl: './target-groups-select.component.scss',
})
export class TargetGroupsSelectComponent implements OnInit {
  private _items: TargetGroup[] = [];
  private valueChangesSet = false;

  @Input() form!: FormGroup;
  @Input() selectedFormControlName: string = '';
  @Input() set items(items: TargetGroup[]) {
    if (items.length > 0) {
      this._items = items;
      if (!this.valueChangesSet) {
        this.filteredItems = this.inputControl.valueChanges.pipe(
          startWith<string | null>(''),
          map((value) => (typeof value === 'string' ? value : this.lastFilter)),
          map((filter) => this.filter(filter)),
        );
      }
    }
  }

  get items(): TargetGroup[] {
    return this._items;
  }

  inputControl = new FormControl<string>('');

  selectedItems: number[] = new Array<number>();

  filteredItems: Observable<TargetGroup[]> = new Observable();
  lastFilter: string = '';

  constructor(private dialog: MatDialog) {}

  filter(filter: string): TargetGroup[] {
    this.lastFilter = filter;
    if (filter) {
      return this.items.filter((option) => {
        return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.items.slice();
    }
  }

  optionClicked(event: Event, itemId: number) {
    event.stopPropagation();
    this.toggleSelection(itemId);
  }

  toggleSelection(itemId: number) {
    if (!this.isSelected(itemId)) {
      this.selectedItems.push(itemId);
    } else {
      this.remove(itemId, false);
    }

    this.inputControl.setValue('');
    this.form.controls[this.selectedFormControlName].setValue(
      this.selectedItems,
    );
  }

  remove(itemId: number, updateFormValue = true): void {
    const index = this.selectedItems.findIndex(
      (selectedId) => selectedId === itemId,
    );

    if (index >= 0) {
      this.selectedItems.splice(index, 1);

      if (updateFormValue) {
        this.form.controls[this.selectedFormControlName].setValue(
          this.selectedItems,
        );
      }
    }
  }

  isSelected(itemId: number): boolean {
    return !!this.selectedItems.find((selectedId) => selectedId === itemId);
  }

  displayFn(_: string): string {
    return '';
  }

  getDisplayName(itemId: number) {
    const item = this.items.find((item) => item.id === itemId);
    return item?.name;
  }

  get selectedFormControl(): AbstractControl {
    return this.form.controls[this.selectedFormControlName];
  }

  ngOnInit(): void {
    this.selectedItems = this.form.controls[this.selectedFormControlName].value;

    this.form.controls[this.selectedFormControlName].valueChanges.subscribe(
      (val) => (this.selectedItems = val),
    );
  }

  addAndSelect() {
    const dialogRef = this.dialog.open(TargetGroupOverlayPageComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'overlay-page-dialog',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res?.targetGroup) {
        this._items.push(res.targetGroup);
        this.toggleSelection(res.targetGroup.id);
      }
    });
  }
}
