import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventCategory } from '@models/event-categories/event-category.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventCategoriesService } from '@services/event-categories.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SharedService } from '@services/shared/shared.service';
import { CustomDialogData } from '@models/overlay-dialogs/custom-dialog-data.model';
import { CustomDialogComponent } from '@modules/shared/dialogs/custom-dialog/custom-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { CategorySelectComponent } from '@modules/shared/components/category-select/category-select.component';
import { TargetGroup } from '@models/shared/target-group.model';
import { SaveTargetGroupRequest } from '@models/shared/dto/save-target-group.request';

@Component({
  selector: 'app-target-group-overlay-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    CategorySelectComponent,
  ],
  providers: [EventCategoriesService, SharedService],
  templateUrl: './target-group-overlay-page.component.html',
  styleUrl: './target-group-overlay-page.component.scss',
})
export class TargetGroupOverlayPageComponent {
  isEdit: boolean = false;

  allCategories: EventCategory[] = [];
  categories: EventCategory[] = [];
  subcategories: EventCategory[] = [];

  targetGroup?: TargetGroup;

  form: FormGroup;

  constructor(
    private eventCategoriesService: EventCategoriesService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<TargetGroupOverlayPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {
    if (data) {
      this.isEdit = data.isEdit;
      this.targetGroup = data.targetGroup;
    }

    this.form = this.fb.group({
      name: [this.targetGroup?.name || '', Validators.required],
      description: [this.targetGroup?.description || ''],
      toneOfVoice: [this.targetGroup?.toneOfVoice || '', Validators.required],
      eventMaterialsImageStyle: [
        this.targetGroup?.eventMaterialsImageStyle || '',
        Validators.required,
      ],
      visualElementStyle: [
        this.targetGroup?.visualElementStyle || '',
        Validators.required,
      ],
      categories: [
        this.targetGroup?.getEventCategoriesIds() || [],
        Validators.required,
      ],
      subcategories: [
        this.targetGroup?.getEventSubcategoriesIds() || [],
        Validators.required,
      ],
    });

    if (data?.allCategories !== undefined) {
      this.allCategories = data.allCategories;

      this.filterAndSortCategoriesAndSubcategories();
    } else {
      this.eventCategoriesService
        .getEventCategories()
        .subscribe((eventCategories) => {
          this.allCategories = eventCategories;

          this.filterAndSortCategoriesAndSubcategories();
        });
    }

    this.form.controls['categories'].valueChanges.subscribe((val) => {
      this.onCategoryChange(val);
    });
  }

  filterAndSortCategoriesAndSubcategories() {
    this.categories = this.allCategories
      ?.filter((item: EventCategory) => !item.parentCategoryId)
      .sort((a, b) => a.name.localeCompare(b.name));
    this.subcategories = this.allCategories
      ?.filter((item: EventCategory) => item.parentCategoryId)
      .sort((a, b) => a.name.localeCompare(b.name));

    this.onCategoryChange(this.targetGroup?.getEventCategoriesIds());
  }

  onCategoryChange(val?: number[]) {
    if (val !== undefined && val.length > 0) {
      this.subcategories = this.allCategories
        .filter(
          (item: EventCategory) =>
            item.parentCategoryId && val.includes(item.parentCategoryId),
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      let selectedSubcategories: number[] = this.form.value.subcategories;

      if (selectedSubcategories) {
        selectedSubcategories = selectedSubcategories.filter(
          (selectedCategoryId) =>
            this.subcategories
              .map((category) => category.id)
              .includes(selectedCategoryId),
        );
        this.form.controls['subcategories'].patchValue(selectedSubcategories);
      }
    } else {
      this.subcategories = this.allCategories
        .filter((item: EventCategory) => item.parentCategoryId)
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  createOrUpdate() {
    if (this.isFormValid()) {
      const value = this.form.value;

      const categoryIds = [...new Set([...value.subcategories])];

      const saveReq: SaveTargetGroupRequest = {
        name: value.name,
        description: value.description,
        toneOfVoice: value.toneOfVoice,
        eventMaterialsImageStyle: value.eventMaterialsImageStyle,
        visualElementStyle: value.visualElementStyle,
        categoryIds: categoryIds,
      };

      if (this.isEdit) {
        saveReq.id = this.targetGroup?.id;
      }

      this.sharedService.saveTargetGroup(saveReq).subscribe((targetGroup) => {
        const confirmation =
          'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.PRODUCT_CONFIRMATION_MESSAGE';
        const okButtonMessage =
          'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.OK';
        const title =
          'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.CONFIRMATION_TITLE';

        const data: CustomDialogData = {
          confirmation: confirmation,
          title: title,
          cancelButtonMessage: okButtonMessage,
          id: targetGroup.id,
        };

        this.dialog.open(CustomDialogComponent, {
          maxWidth: '455px',
          width: '100%',
          height: 'auto',
          data: data,
        });

        this.dialogRef.close({
          targetGroup: targetGroup,
          refresh: true,
        });
      });
    }
  }

  isFormValid(): boolean {
    this.form.markAllAsTouched();

    return this.form.valid;
  }
}
